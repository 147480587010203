import React from 'react'
import PropTypes from 'prop-types'

import ContentfulText from '../ContentfulText'
import './styles.scss'


const PrivacyPolicy = ({data}) => {
  return (
    <div className='eg-privacy-policy'>
      <ContentfulText data={data} />
    </div>
  )
}

PrivacyPolicy.propTypes = {
  data: PropTypes.string.isRequired,
}

export default PrivacyPolicy
