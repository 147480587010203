import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'

import MainLayout from '../components/MainLayout'
import PrivacyPolicy from '../components/PrivacyPolicy'
import Demo from '../components/Demo'
import { EGAnalytics, analyticEvents } from '../services/analytics'
import { getCmsContentType } from '../services/util'

export default (props) => {
  const headerData = getCmsContentType(props, process.env.GATSBY_CONTENTFUL_ID_HOME)
  const data = _.get(props, 'data.contentfulTermsOfService.touContent.touContent')

  useEffect(() => {
    EGAnalytics.track(analyticEvents.PRIVACY_POLICY_VIEWED)
  }, [])

  return (
    <MainLayout data={headerData }>
      <PrivacyPolicy data={data} />
      <Demo />
    </MainLayout>
  )
}


export const pageQuery = graphql`
  query privacyPolicyPage {
    allContentfulHomeV3 {
      edges {
        node {
          title
          contentful_id
          hero {
            cta
          }
        }
      }
    }

    contentfulTermsOfService(type: {eq: "pp"}) {
      touContent {
        touContent
      }
    }
  }
`
